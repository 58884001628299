<template>
  <div v-loading="!thinkData.content" class="container">
    <LeftSidebar class="lt" :data="leftData" @changeGetType="changeGetType" />
    <div class="rt">
      <Title :title="title" />
      <div v-if="getType" class="listContainer">
        <div
          v-for="(item, index) of thinkData.content"
          :key="index"
          class="item"
          @click="jump(item.url)"
        >
          <div class="content">
            <div class="title">{{ item.title | textFilter }}</div>
            <div class="date">
              {{ getDate(item.issueDate) | date('MM-DD') | textFilter }}
            </div>
          </div>
          <div class="content">
            <div class="des" v-html="item.content"></div>
            <div class="year">
              {{ getDate(item.issueDate) | date('YYYY') | textFilter }}
            </div>
          </div>
        </div>
      </div>
      <div v-else class="mechanismContainer">
        <div
          v-for="(item, index) of mechanismData"
          :key="index"
          class="mechanism"
          :style="{ backgroundColor: item.color }"
          @click="jump(item.link)"
        >
          <div>{{ item.name }}</div>
          <div>{{ item.des }}</div>
        </div>
        <div class="noMechanism"></div>
      </div>

      <Pagination
        class="pagination"
        :page-info="pageInfo"
        @changePage="changePage"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Title from '@/components/Title'
import LeftSidebar from '@/components/LeftSidebar'
import Pagination from '@/components/Pagination'
export default {
  name: 'Think',
  components: {
    Title,
    LeftSidebar,
    Pagination,
  },
  data() {
    return {
      getType: 'getExpertInterview',
      index: 0,
      leftData: {
        icon: require('@/assets/think/icon.png'),
        title: 'dc162c6',
        list: [
          {
            name: '6412242',
            path: '',
            event: 'changeGetType',
            value: 'getExpertInterview',
          },
          {
            name: '20810aa',
            path: '',
            event: 'changeGetType',
            value: 'getLawBbs',
          },
          {
            name: '6e68c92',
            path: '',
            event: 'changeGetType',
            value: 'getResearch',
          },
          {
            name: 'a6cfbf5',
            path: '',
            event: 'changeGetType',
            value: '',
          },
        ],
      },
      mechanismData: [
        {
          name: '西南政法大学',
          des: '中国—东盟法律研究中心',
          link: 'http://cicc.court.gov.cn/html/1/218/347/327/339/index.html',
          color: '#FFAF2C',
        },
        {
          name: '蓝海法律查明',
          des: '和商事调解中心',
          link: 'https://www.bcisz.org/plug/ap/?fromcicc',
          color: '#4569FF',
        },
        {
          name: '中国政法大学',
          des: '外国法查明研究中心',
          link: 'http://cicc.court.gov.cn/html/1/218/347/327/341/index.html',
          color: '#03A2FF',
        },
        {
          name: '华东政法大学',
          des: '外国法查明研究中心',
          link: 'http://cicc.court.gov.cn/html/1/218/347/327/343/index.html',
          color: '#00C09F',
        },
        {
          name: '武汉大学',
          des: '外国法查明研究中心',
          link: 'http://cicc.court.gov.cn/html/1/218/347/327/345/index.html',
          color: '#9B4FFF',
        },
      ],
      pageInfo: {
        page: 1,
        size: 8,
        total: 0,
      },
    }
  },
  computed: {
    ...mapGetters(['thinkData']),
    title() {
      const str = this.leftData.list[this.index].name
      return this.$t(str)
    },
  },
  watch: {
    thinkData(newVal) {
      if (newVal) {
        this.pageInfo.total = newVal.page.total
      }
    },
    getType(newVal) {
      this.resetPageInfo()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions(['getExpertInterview', 'getLawBbs', 'getResearch']),
    getDate(dateStr) {
      return dateStr ? dateStr.split('T')[0] : ''
    },
    getData() {
      const type = this.getType
      if (type) {
        this[type](this.pageInfo)
      }
    },
    resetPageInfo() {
      this.pageInfo = {
        page: 1,
        size: 8,
        total: 0,
      }
    },
    jump(url) {
      window.open(url)
    },
    changePage(page) {
      this.pageInfo.page = page
      this.getData()
    },
    changeGetType(val, item, index) {
      this.getType = val
      // this.title = item.name
      this.index = index
      this.getData()
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
.container {
  display: flex;
  justify-content: space-between;
  width: @uni-width;
  min-height: calc(100vh - 334px);
  margin: 20px auto;
  .rt {
    width: 920px;
    background: #fff;
    box-shadow: 0px 2px 22px 0px rgba(142, 142, 142, 0.18);
    .item {
      margin: 0 30px;
      padding: 30px 0;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.09);
      font-size: 14px;
      color: #333333;
      cursor: pointer;
      &:hover {
        .title,
        .date,
        .year {
          color: @uni-text-color-select;
        }
      }
      .content {
        display: flex;
        justify-content: space-between;
      }
      .title {
        width: 710px;
        margin-bottom: 24px;
        font-size: 16px;
        color: #333333;
      }
      .date {
        font-size: 20px;
        font-weight: 400;
      }
      .des {
        width: 710px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .year {
        position: relative;
        top: -12px;
        font-weight: 400;
      }
    }
    .pagination {
      margin: 30px 30px 40px 0;
    }
    .mechanismContainer {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-top: 48px;
      .mechanism {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 410px;
        height: 200px;
        margin-bottom: 40px;
        border-radius: 10px;
        font-size: 28px;
        font-weight: 600;
        color: #ffffff;
        line-height: 40px;
        cursor: pointer;
        // .name {
        // }
      }
      .noMechanism {
        width: 410px;
        height: 200px;
      }
    }
  }
}
</style>
